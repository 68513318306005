import { mdiFerry ,mdiFormatQuoteOpen,mdiCardAccountPhoneOutline,mdiStoreOutline,mdiCubeOutline,mdiTruckCargoContainer} from "@mdi/js";
export const dashboardIcons = {
    "BOAT_ORDERS":mdiFerry,
    "QUOTES":mdiFormatQuoteOpen,
    "LEADS":mdiCardAccountPhoneOutline,
    "DEALERS":mdiStoreOutline,
    "DEALER_INVENTORY":mdiCubeOutline,
    "RETAIL_SOLD":mdiTruckCargoContainer
}

