import { Box, Stack, Grid, Typography } from "@mui/material";
import Icon from "@mdi/react";

type CardComponentProps = {
  icon: any;
  title: string;
  stats: string;
};

const CardComponent = ({ icon, title, stats }: CardComponentProps) => {
  return (
    <>
      <Grid item md={1.8} sm={3} xs={5.8} className="card">
        <Stack direction="row" spacing={1} className="card-content">
          <Stack
            className="icon-container"
            justifyContent="center"
            alignItems="center"
          >
            <Icon path={icon} title={title} size={1} className="card-icon" />
          </Stack>
          <Box>
            <Typography className="stats">{stats ? stats : 0} {stats ? "+" : ""}</Typography>
            <Typography>{title}</Typography>
          </Box>
        </Stack>
      </Grid>
    </>
  );
};

export default CardComponent;
